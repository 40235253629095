function makeRandom(seed) {
  function next() {
    seed = seed * 48271 % 2147483647;
    return seed / 2147483648;
  };

  return function random(min, max) {
    const rand = next();
    if (typeof min === 'undefined') {
      return rand;
    } else if (typeof max === 'undefined') {
      if (min instanceof Array) {
        return min[Math.floor(rand * min.length)];
      } else {
        return rand * min;
      }
    } else {
      if (min > max) {
        const tmp = min;
        min = max;
        max = tmp;
      }
  
      return rand * (max - min) + min;
    }
  };
}

module.exports = function draw(p, hash) {
  const seed = hash && parseInt(hash.substring(0, 10)) || (Math.random() * Date.now());
  var random = makeRandom(seed);
  
  // Background colors
  var setBackgroundColor = [
    '#000000',  // black
    '#230037',  // purple
    '#00113C',  // blue
    '#240900',  // red
    '#34012F'   // pink
  ];
  var pickBackgroundColor = random(setBackgroundColor);

  let pickStrokeWeight = p.floor(random(1, 6));

  // Switch settings
  var drawGrid01 = random([1, 2, 3, 4, 5, 6, 7]);
  var drawGrid02 = random([1, 2, 3, 4, 5, 6, 7]);
  var drawGrid03 = random([1, 2, 3, 4, 5, 6, 7]);
  var drawGrid04 = random([1, 2, 3, 4, 5, 6, 7, 8]);

  var loopNumber = 0;

  // Color mode & colors
  p.colorMode(p.HSL, 600, 600, 600);
  p.background(pickBackgroundColor);
  p.stroke(pickBackgroundColor);
  p.strokeWeight(pickStrokeWeight);
  p.strokeCap(p.ROUND);
  p.strokeJoin(p.ROUND);

  let colorPalette01 = {
    name: 'Frosted Glass',
    colors: (x, y) => ([
      p.color(400 - x, 500, 100 + y),
      p.color(600 - x, 600, 300 + y / 4),
      p.color(x, 600 - y / 8, 100 + y / 2),
      p.color(400 - x, 100, 300)
    ])
  };
  let colorPalette02 = {
    name: 'Watermelon',
    colors: (x, y) => ([
      p.color('#38FF13'),
      p.color('#B4FF79'),
      p.color('#FE30A5'),
      p.color('#ED0077')
    ])
  };
  let colorPalette03 = {
    name: 'Blue Grapefruit',
    colors: (x, y) => ([
      p.color(400, 500, 400),
      p.color(600, 400 + (x / 2), 400),
      p.color(600 - (y / 3), 400 + (x / 0.5), 400),
      p.color(400, 400, random(100, 200))
    ])
  };
  let colorPalette04 = {
    name: 'Gee, Biv & Roy',
    colors: (x, y) => ([
      p.color(200 + y, 500, 400),
      p.color(400 + y, 400, 400),
      p.color(400 + y, 600, 400),
      p.color(200 + y, 600, 400)
    ])
  };
  let colorPalette05 = {
    name: 'Crayons',
    colors: (x, y) => ([
      p.color(600 - x, 500 - x, random(350, 450)),
      p.color(400 - (x / 2), random(200, 400), 400 - x / 6),
      p.color(600 - y, 600, 400),
      p.color(600 - x, 600, random(350, 500))
    ])
  };
  let colorPalette06 = {
    name: 'By the Bell',
    colors: (x, y) => ([
      p.color('#9b5de5'),
      p.color('#f15bb5'),
      p.color('#fee440'),
      p.color('#00bbf9')
    ])
  };
  let colorPalette07 = {
    name: 'Spooky',
    colors: (x, y) => ([
      p.color('#ff6d00'),
      p.color('#ff9e00'),
      p.color('#240046'),
      p.color('#9d4edd')
    ])
  };
  let colorPalette08 = {
    name: 'Hues',
    colors: (x, y) => ([
      p.color(266 + (x / 1.5), 500, random(150, 250) + (y / 2)),
      p.color('#70e000'),
      p.color(266 + (x / 1.5), 500, random(150, 250) + (y / 2)),
      p.color(125 + y, 500, random(150, 250) + (y / 2))
    ])
  };
  let colorPalette09 = {
    name: 'Hulk',
    colors: (x, y) => ([
      p.color('#9F1FEF'),
      p.color(200, 500, random(150, 250)),
      p.color('#EA07FF'),
      p.color(200, 600, random(275, 325))
    ])
  };
  let colorPalette10 = {
    name: 'Lavender',
    colors: (x, y) => ([
      p.color('#e4a5ff'),
      p.color('#cbbdff'),
      p.color('#7b2cbf'),
      p.color('#bfcaff')
    ])
  };
  let colorPalette11 = {
    name: 'Heat Index',
    colors: (x, y) => ([
      p.color(0 + (y / 4), 600, 300),
      p.color(20 + (y / 4), 600, 300),
      p.color(40 + (y / 4), 600, 300),
      p.color(600 - (y / 4), 600, 300),
    ])
  };
  let colorPalette12 = {
    name: 'Warm Front',
    colors: (x, y) => ([
      p.color(300 + (y / 12), 300, 300),
      p.color(400 + (y / 8), 600, 300),
      p.color(40 + (y / 4), 600, 300),
      p.color(600 - (y / 4), 600, 300),
    ])
  };
  let colorPalette13 = {
    name: 'Electric Blue',
    colors: (x, y) => ([
      p.color(200 + (x / 12), 600, 300),
      p.color(300 + (x / 8), 600, 300),
      p.color(100 + (x / 4), 600, 300),
      p.color(400 - (x / 4), 600, 300),
    ])
  };
  let colorPalette14 = {
    name: 'Lemons & Limes',
    colors: (x, y) => ([
      p.color(100 - (x / 12), 600, 300),
      p.color(300 - (y / 3), 600, 300),
      p.color(200 - (y / 3), 600, 300),
      p.color(200 - (x / 4), random(400, 600), 300),
    ])
  };
  let colorPalette15 = {
    name: 'Candy',
    colors: (x, y) => ([
      p.color('#ffbe0b'),
      p.color('#ff006e'),
      p.color('#8338ec'),
      p.color('#3a86ff'),
    ])
  };
  let colorPalette16 = {
    name: 'Marigold',
    colors: (x, y) => ([
      p.color('#555bd3'),
      p.color('#08cccd'),
      p.color('#fbf474'),
      p.color('#ff809b'),
    ])
  };
  let colorPalette17 = {
    name: 'Sunrise',
    colors: (x, y) => ([
      p.color(450 - (x / 10), random(500,600), 300),
      p.color(50 + (y / 10), 600, 300),
      p.color(450 - (x / 6), random(500,600), 300),
      p.color(50 + (y / 10), 600, 300)
    ])
  };
  let colorPalette18 = {
    name: 'Citrus',
    colors: (x, y) => ([
      p.color(50+ (y / 2), 500, 300),
      p.color(50+ (y / 2), 500, 300),
      p.color(150 + (y / 2), 500, 300),
      p.color(150 + (y / 2), 500, 300)
    ])
  };
  let colorPalette19 = {
    name: 'Express',
    colors: (x, y) => ([
      p.color('#f72585'),
      p.color('#3a0ca3'),
      p.color('#4361ee'),
      p.color('#7209b7')
    ])
  };
  let colorPalette20 = {
    name: 'Gold n Silver',
    colors: (x, y) => ([
      p.color('#7FEFBD'),
      p.color('#CBA135'),
      p.color('#171717'),
      p.color('#F3EFEO')
    ])
  };
  let colorPalette21 = {
    name: 'Scoobs',
    colors: (x, y) => ([
      p.color('#89542F'),
      p.color('#FF9E1B'),
      p.color('#89199C'),
      p.color('#00C4B3')
    ])
  };
  let colorPalette22 = {
    name: 'Flowers',
    colors: (x, y) => ([
      p.color('#FF829D'),
      p.color('#FC9C4D'),
      p.color('#FF6653'),
      p.color('#FFEF49')
    ])
  };
  let colorPalette23 = {
    name: 'Malibu Spring',
    colors: (x, y) => ([
      p.color('#FFFFFF'),
      p.color('#5DC3FE'),
      p.color('#00FFAB'),
      p.color('#0000FF')
    ])
  };
  let colorPalette24 = {
    name: 'Golf Clap',
    colors: (x, y) => ([
      p.color('#F3F1E2'),
      p.color('#F0A9C5'),
      p.color('#5BBC99'),
      p.color('#106F46')
    ])
  };
  let colorPalette25 = {
    name: 'Taffy',
    colors: (x, y) => ([
      p.color('#EFEF8F'),
      p.color('#FD95FF'),
      p.color('#FBBBB1'),
      p.color('#FF3703')
    ])
  };
  let colorPalette26 = {
    name: 'OA',
    colors: (x, y) => ([
      p.color('#F8F3E9'),
      p.color('#085A2E'),
      p.color('#FEAA00'),
      p.color('#B183DB')
    ])
  };
  let colorPalette27 = {
    name: 'Cardio',
    colors: (x, y) => ([
      p.color('#FEC000'),
      p.color('#FE497B'),
      p.color('#7F35FD'),
      p.color('#1BC7CC')
    ])
  };
  let colorPalette28 = {
    name: 'Sunflower',
    colors: (x, y) => ([
      p.color('#BB8A52'),
      p.color('#FFBA00'),
      p.color('#6D9773'),
      p.color('#244F43')
    ])
  };
  let colorPalette29 = {
    name: 'Chalk',
    colors: (x, y) => ([
      p.color('#C4EEFF'),
      p.color('#FFDBCC'),
      p.color('#FBD3FF'),
      p.color('#76B6F0')
    ])
  };
  let colorPalette30 = {
    name: 'Autumn',
    colors: (x, y) => ([
      p.color('#000000'),
      p.color('#FFBB31'),
      p.color('#FE3A3A'),
      p.color('#8344FF')
    ])
  };
  let colorPalette31 = {
    name: 'Gold',
    colors: (x, y) => ([
      p.color('#977B60'),
      p.color('#FCF1DA'),
      p.color('#F6C561'),
      p.color('#E6975E')
    ])
  };
  let colorPalette32 = {
    name: 'Mulled',
    colors: (x, y) => ([
      p.color('#F1AA9B'),
      p.color('#F0C38E'),
      p.color('#48426D'),
      p.color('#464162')
    ])
  };
  let colorPalette33 = {
    name: 'Marzipan',
    colors: (x, y) => ([
      p.color('#AFC3A8'),
      p.color('#F7D78C'),
      p.color('#A6D3F2'),
      p.color('#FF6957')
    ])
  };
  let colorPalette34 = {
    name: 'Helimetropium',
    colors: (x, y) => ([
      p.color('#FFB40B'),
      p.color('#3DCD12'),
      p.color('#FF393A'),
      p.color('#BC43FE')
    ])
  };
  let colorPalette35 = {
    name: 'Bubblegum',
    colors: (x, y) => ([
      p.color('#01AD41'),
      p.color('#FE3EB3'),
      p.color('#FFB71C'),
      p.color('#0972CE')
    ])
  };
  let colorPalette36 = {
    name: 'Topo',
    colors: (x, y) => ([
      p.color('#20C7D1'),
      p.color('#F98F44'),
      p.color('#EB012D'),
      p.color('#D67BDE')
    ])
  };
  let colorPalette37 = {
    name: 'TMNT',
    colors: (x, y) => ([
      p.color('#03BBF9'),
      p.color('#F0A408'),
      p.color('#673397'),
      p.color('#E32A16')
    ])
  };
  let colorPalette38 = {
    name: 'Friday',
    colors: (x, y) => ([
      p.color('#FF33FF'),
      p.color('#005196'),
      p.color('#00D8DC'),
      p.color('#FFCC00')
    ])
  };
  

  let paletteObject = random([colorPalette01, colorPalette02, colorPalette03, colorPalette04, colorPalette05, colorPalette06, colorPalette07, colorPalette08, colorPalette09, colorPalette10,
                              colorPalette11, colorPalette12, colorPalette13, colorPalette14, colorPalette15, colorPalette16, colorPalette17, colorPalette18, colorPalette19, colorPalette20,
                              colorPalette21, colorPalette22, colorPalette23, colorPalette24, colorPalette25, colorPalette26, colorPalette27, colorPalette28, colorPalette29, colorPalette30,
                              colorPalette31, colorPalette32, colorPalette33, colorPalette34, colorPalette35, colorPalette36, colorPalette37, colorPalette38]);
  
  const gridSpacing = p.floor(random(30, 60));
  
  // Draw the grids via loops
  for (var x = 0; x++ <= p.width; x += gridSpacing) {
    for (var y = 0; y++ <= p.height; y += gridSpacing) {
      loopNumber++;
      var flipSizes;

      let palette = paletteObject.colors(x, y);

      // Every other
      if ((loopNumber % 2) === 0) {
        flipSizes = 10;
        flipSizesSmall = 14;
      } else {
        flipSizes = 40;
        flipSizesSmall = 24;
      }

      ////////////////////////////////////////////////////////////
      // Grid 01
      ////////////////////////////////////////////////////////////

      // Color
      p.fill(palette[0]);
      // Layout
      switch(drawGrid01) {
        case 1:
          p.rect(x + 10, y + 10, 30, 30, p.floor(random(0, 4)));
          break;
        case 2:
          p.rect(x + 10, y + 10, 20, p.floor(random(10, 40)), p.floor(random(10, 20)));
          break;
        case 3:
          p.rect(x + 10, y + 10, 30, 30, p.floor(random(20, 40)));
          break;
        case 4:
          p.circle(x, y, 30);
          break;
        case 5:
          // None
          break;
        case 6:
          p.circle(x, y, flipSizes);
          break;
        case 7:
          p.circle(x, y, flipSizes * 1.25);
          break;
      }
      drawGrid01

      ////////////////////////////////////////////////////////////
      // Grid 02
      ////////////////////////////////////////////////////////////

      // Color
      p.fill(palette[1]);
      // Layout
      switch(drawGrid02) {
        case 1:
          p.rect(x + 20, y + 20, 40, 40, 0);
          break;
        case 2:
          p.circle(x, y, 24);
          break;
        case 3:
          p.circle(x, y, 10 + (y / 10));
          break;
        case 4:
          p.circle(x, y - 10, 12);
          break;
        case 5:
          p.circle(x, y, 40 - (y / 20));
          break;
        case 6:
          p.rect(x, y, 20 + (y / 4), p.floor(random(5, 15)), 0);
          break;
        case 7:
          p.rect(x, y, flipSizes + (y / 4), p.floor(random(5, 15)), 0);
          break;
      }
      drawGrid02

      ////////////////////////////////////////////////////////////
      // Grid 03
      ////////////////////////////////////////////////////////////

      // Color
      p.fill(palette[2]);
      // Layout
      switch(drawGrid03) {
        case 1:
          p.rect(x, y, 20, 20, 0);
          break;
        case 2:
          p.rect(x, y, p.floor(random(30, 40)), p.floor(random(30, 40)), 10);
          break;
        case 3:
          p.rect(x, y, p.floor(random(30, 40)), p.floor(random(10, 20)), 20);
          break;
        case 4:
          p.rect(x, y, 10, p.floor(random(40, 60)), 20);
          break;
        case 5:
          p.arc(x, y, 30 * 2, 30 * 2, p.TWO_PI, p.PI / 2);
          break;
        case 6:
          p.rect(x, y - p.floor(random(gridSpacing / 4, gridSpacing / 2)), 8, 40, 20);
          break;
        case 7:
          p.rect(x, y, flipSizesSmall, flipSizesSmall, 0);
          break;
      }
      drawGrid03

      ////////////////////////////////////////////////////////////
      // Grid 04
      ////////////////////////////////////////////////////////////

      // Color
      p.fill(palette[3]);
      // Layout
      switch(drawGrid04) {
        case 1:
          p.rect(x, y, 30, 30, 0);
          break;
        case 2:
          p.rect(x, y, 40, 40, y);
          break;
        case 3:
          p.rect(x + 10, y + p.floor(random(4, 20)), p.floor(random(8, 12)), p.floor(random(10, 30)), 10);
          break;
        case 4:
          p.triangle(x, y + 30, x + p.floor(random(0, 30)), y, x + 30, y + 30)
          break;
        case 5:
          p.triangle(x, y + 30, x + 30, y, x + 30, y + 30)
          break;
        case 6:
          p.rect(x, y, 10, flipSizes, flipSizes);
          break;
        case 7:
          p.rect(x - 10, y + p.floor(random(4, 20)), p.floor(random(4, 20)), p.floor(random(4, 30)), p.floor(random(4, 10)));
          break;
        case 8:
          // None
          break;
      }
      drawGrid04

      ////////////////////////////////////////////////////////////
    }
  }

  const backgroundNames = {
    '#000000':  "Black",
    '#230037':  "Purple",
    '#00113C':  "Blue",
    '#240900':  "Red",
    '#34012F':  "Pink"
  }
  const namedGrid01 = {
    '1': "Pattern 01",
    '2': "Pattern 02",
    '3': "Pattern 03",
    '4': "Pattern 04",
    '5': "None",
    '6': "Pattern 06",
    '7': "Pattern 07"
  }
  const namedGrid02 = {
    '1': "Pattern 01",
    '2': "Pattern 02",
    '3': "Pattern 03",
    '4': "Pattern 04",
    '5': "Pattern 05",
    '6': "Pattern 06",
    '7': "Pattern 07"
  }
  const namedGrid03 = {
    '1': "Pattern 01",
    '2': "Pattern 02",
    '3': "Pattern 03",
    '4': "Pattern 04",
    '5': "Pattern 05",
    '6': "Pattern 06",
    '7': "Pattern 07"
  }
  const namedGrid04 = {
    '1': "Pattern 01",
    '2': "Pattern 02",
    '3': "Pattern 03",
    '4': "Pattern 04",
    '5': "Pattern 05",
    '6': "Pattern 06",
    '7': "Pattern 07",
    '8': "None"
  }

  const backgroundColorNames = (pickBackgroundColor) => backgroundNames[pickBackgroundColor] || "none";
  const gridNames01 = (drawGrid01) => namedGrid01[drawGrid01] || "none";
  const gridNames02 = (drawGrid02) => namedGrid01[drawGrid02] || "none";
  const gridNames03 = (drawGrid03) => namedGrid01[drawGrid03] || "none";
  const gridNames04 = (drawGrid04) => namedGrid01[drawGrid04] || "none";
  
  // Properties
  return {
    'Palette': paletteObject.name,
    'BG & Stroke': backgroundNames[pickBackgroundColor],
    'Stroke px': pickStrokeWeight,
    'Grid px': gridSpacing,
    'Grid 01': namedGrid01[drawGrid01],
    'Grid 02': namedGrid02[drawGrid02],
    'Grid 03': namedGrid03[drawGrid03],
    'Grid 04': namedGrid04[drawGrid04]
  };
}