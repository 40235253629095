import React, {useEffect, useRef} from 'react';
import makeOrb from './render.js';
import htraits from './htraits.js';

type Props = {
  animated?: boolean;
  hash?: string;
  density?: number;
  onTraits?: (traits: {[key: string]: string}) => void;
  saveRef?: React.MutableRefObject<((name: string) => void) | null>;
};

export default function Preview({hash, density = 2, onTraits, saveRef, animated = false}: Props) {
  const canvas = useRef<HTMLCanvasElement | null>(null);
  const tOffset = useRef(0);

  useEffect(() => {
    if (typeof window === 'undefined' || !canvas.current) {
      return;
    }

    const ctx = canvas.current.getContext('2d')!;
    const orb = makeOrb(hash);
    onTraits && onTraits(htraits(orb.tr));

    let raf: number;
    const t0 = Date.now();

    function render() {
      orb.orb(ctx, 600 * density, Date.now() - t0 + tOffset.current);
      if (animated) {
        raf = requestAnimationFrame(render);
      }
    }

    render();
    return () => {
      if (animated) {
        tOffset.current += Date.now() - t0;
      }
      cancelAnimationFrame(raf);
    };
  }, [hash, density, onTraits, saveRef, animated]);

  return (
    <div className="frame">
      <canvas ref={canvas} width={600 * density} height={600 * density} />
      <style jsx>{`
        .frame > :global(canvas) {
          width: 100% !important;
          height: auto !important;
        }
        .frame {
          aspect-ratio: 1;
        }
      `}</style>
    </div>
  );
}
