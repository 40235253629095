module.exports = function makeOrb(hash) {
  // https://stackoverflow.com/a/47593316
  var
    {cos:C, sin:S, trunc:T,imul:I,sqrt:Q,max,min,abs,PI} = Math,
    mulberry32 = (a) => {
      var rng = () => {
        var t = (a += 0x6d2b79f5);
        t = I(t ^ (t >>> 15), t | 1);
        t ^= t + I(t ^ (t >>> 7), t | 61);
        return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
      };
      rng.clone = () => mulberry32(a);
      return rng;
    },
    rx = (v, a) => [
      v[0],
      v[1] * C(a) - v[2] * S(a),
      v[1] * S(a) + v[2] * C(a),
    ],
    ry = (v, a) => [
      v[0] * C(a) + v[2] * S(a),
      v[1],
      -v[0] * S(a) + v[2] * C(a),
    ],
    rz = (v, a) => [
      v[0] * C(a) - v[1] * S(a),
      v[0] * S(a) + v[1] * C(a),
      v[2],
    ],
    // Euler angles
    e = (v, x, y, z) => rz(ry(rx(v, x), y), z),
    dist = (a, b) => Q((a[0] - b[0]) ** 2 + (a[1] - b[1]) ** 2 + (a[2] - b[2]) ** 2),
    cross = (a, b) => [
      a[1] * b[2] - a[2] * b[1],
      a[2] * b[0] - a[0] * b[2],
      a[0] * b[1] - a[1] * b[0],
    ],
    smul = (f, v) => [f * v[0], f * v[1], f * v[2]],
    add = (a, b) => [a[0] + b[0], a[1] + b[1], a[2] + b[2]],
    madd = (X, ...args) => args.map(a => add(X, a)),
    mag = (a) => Q(a[0] ** 2 + a[1] ** 2 + a[2] ** 2),
    normalize = (a) => {
      var m = mag(a);
      return [a[0] / m, a[1] / m, a[2] / m];
    },
    quad = (a, b, c, d, clr) => ({
      a, b, c, d, clr,
      D: [a[2] + b[2] + c[2] + d[2]] / 4.0,
    }),
    normal3 = (a, b, c) => {
      var n = cross(add(c, smul(-1, a)), add(b, smul(-1, a)));
      return (n[0] || n[1] || n[2]) ? normalize(n) : null;
    },
    normal = (qu) => normal3(qu.a, qu.b, qu.c) || normal3(qu.c, qu.b, qu.d),

  createFeature = (quads, qu, f, t) => {
    var N = normal(qu);
    if (!N) {
      return;
    }
    var { a, b, c, d } = qu;

    var side = max(dist(a, b), dist(c, d)) * (0.75 + abs(1 * S(t * 2) ** 2));
    var [A, B, C, D] = madd(smul(side, N), a, b, c, d);

    if (f == 'pyramid') {
      A = B = C = D = smul(0.25, add(A, add(B, add(C, D))));
    }

    var addQuad = (a, b, c, d) => quads.push(quad(a, b, c, d, qu.clr));
    addQuad(A, B, C, D);
    if (f == 'float') {
      [a, b, c, d] = madd(smul(side * 0.9, N), a, b, c, d);
      addQuad(b, a, d, c);
    }
    addQuad(a, b, A, B);
    addQuad(c, a, C, A);
    addQuad(b, d, B, D);
    addQuad(d, c, D, C);
  },

  shuffleArray = (array, rng) => {
    // https://stackoverflow.com/a/12646864
    for (let i = array.length - 1; i > 0; i--) {
      var j = T(rng() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  },

  palette = (rng) => {
    // palettes.js
    var palettes = {
      D: [0x1156424, 0xca583a, 0xc25d3a, 0xee493a, 0xb4563f,],
      E: [0x196432, 0x10e640d, 0x1f6432, 0x10e5416, 0x1103e2e,],
      F: [0xce6417, 0xa86460, 0xae3d2f, 0x1605032, 0xdf6437,],
      I: [0x370e53, 0x371c5a, 0x2b5842, 0x3c0114, 0x78011e,],
      J: [0xcb381e, 0xb5312b, 0x993539, 0x854b47, 0x7e5057,],
      K: [0x38, 0x41, 0x4d, 0x57, 0x5e,],
      L: [0x8553d, 0x33633c, 0x464238, 0xab6426, 0x107102f,],
    },
    probs = {
      D: 0.2,
      E: 0.03,
      F: 0.03,
      I: 0.03,
      J: 0.3,
      K: 0.01,
      L: 0.4,
    };

    var [pal_name, p_pal] = rngd(rng, probs),
        colors = palettes[pal_name];
    
    shuffleArray(colors, rng);
    var obj = (hsl) => ({h: hsl >> 16, s: (hsl >> 8) & 0xff, l: hsl & 0xff});
    return {pal: {s: obj(colors[0]), f1: obj(colors[1]), f2: obj(colors[2])}, pal_name, p_pal};
  },

  rngd = (rng, ps) => {
    var value = rng(), pSum = 0, p;
    for (var s in ps) {
      pSum += p = ps[s];
      if (value <= pSum) {
        return [s, p];
      }
    }
    return [s, p]; // fail-safe in case the final pSum != 1
  },

  traits = (rng) => {
    var {pal, pal_name, p_pal} = palette(rng);
    var [ft,p_ft] = rngd(rng, {
      few: 0.31,
      some: 0.31,
      loads: 0.31,
      all: 0.01,
      hslices: 0.01,
      vslices: 0.01,
      none: 0.01,
      spiral: 0.01,
      check: 0.01,
      ring: 0.01,
    });
    var [lmod, p_lmod] = (p_ft < 0.1) ? ['glow',1] : rngd(rng, {glow: 0.01, '-': 0.99});

    var fp = {cube: 0.1, pyramid: 0.3, flat: 0.3, float: 0.3};
    var [f1, p_f1] = !['none'].includes(ft) ? rngd(rng, fp) : [false, 1];
    var hasF2 = ['loads', 'all', 'few', 'some'].includes(ft);
    var [f2, p_f2] = hasF2 ? rngd(rng, fp) : [false, 1];

    var expensiveFt = ['loads', 'all', 'spiral', 'check', 'vslices', 'hslices'];
    var bound = expensiveFt.includes(ft) && f1 != 'flat' && f2 != 'flat' ? 14 : 26;
    var div = 11 + T(rng() * bound);
    if (ft == 'spiral') {
      div = T(div / 2) * 2; // even - avoid discontinuities
    } else if (ft == 'ring' || ft == 'hslices') {
      div = T(div / 2) * 2 + 1; // odd - centered ring / same-color polar caps
    }

    // sightly more perceptual rarity - type of palette, feature layout, light mod
    // f1/f2 don't tell a relevant picture, so dropping them from this metric.
    var rarity = 100 * p_pal * p_ft * p_lmod;

    return {
      pal,
      pal_name,
      rng,
      div,
      f1,
      f2,
      ft,
      lmod,
      rarity,
    };
  },

  render = (ctx, s, t, tr) => {
    t /= 1000;
    
    // Draw background.
    var grad = ctx.createLinearGradient(0, 0, s, s);
    var bkgHue = (tr.pal.s.h+20)%360;
    grad.addColorStop(0,`hsl(${bkgHue},${tr.pal.s.s}%,15%)`);
    grad.addColorStop(1,`hsl(${bkgHue},${tr.pal.s.s}%,5%)`);
    ctx.fillStyle = grad;
    ctx.fillRect(0, 0, s, s);
 
    var zCenter = 3, radius = 0.25 + abs(0.03 * S(t * 2) ** 2);

    var 
      d = 0.2,
      r = 0.03,
      proj = (v) => [
        (s / 2) * (1 + (v[0] * d) / v[2] / r),
        (s / 2) * (1 + (v[1] * d) / v[2] / r),
      ],
      drawQuad = (qu) => {
        // project and draw a lit quad
        var l = max(
          min(20, qu.clr.l),
          qu.clr.l - (qu.D - (zCenter - radius)) ** 2 * 1600,
          tr.lmod == 'glow' ? qu.clr.l - (qu.D - zCenter) ** 2 * 1800 : 0
        );
        ctx.fillStyle = `hsl(${qu.clr.h},${qu.clr.s}%,${l}%)`;

        ctx.beginPath();
        let A = proj(qu.a),
          B = proj(qu.b),
          C = proj(qu.c),
          D = proj(qu.d);
        ctx.moveTo(A[0], A[1]);
        ctx.lineTo(B[0], B[1]);
        ctx.lineTo(D[0], D[1]);
        ctx.lineTo(C[0], C[1]);
        ctx.closePath();
        ctx.fill();
      };

    // 1. Compute a 2d array that maps a 2d grid to 3D vertex positions on the sphere.
    let vertices = [], N = tr.div, M = N * 2;
    for (let i = 0; i < M; i++) {
      let row = [];
      var u = i * 2 * PI / M;
      for (let j = 0; j <= N; j++) {
        var v = j * PI / N;

        var w = e(
          [radius * C(u) * S(v), radius * S(u) * S(v), radius * C(v)],
          PI / 3,
          t,
          t,
        );
        w[2] += zCenter;
        row.push(w);
      }
      vertices.push(row);
    }

    // 2. Assemble vertices into quads and construct features as needed.
    let quads = [];
    for (let i = 0; i < M; i++) {
      for (let j = 0; j < N; j++) {
        let I = (i + 1) % M, // [0, M) populated ; we connect the last meridian to the first to save one slice of vertices
          J = (j + 1); // [0, N] populated; we explicitly have north pole to south pole vertices

        let qu = quad(vertices[i][j], vertices[I][j], vertices[i][J], vertices[I][J], tr.pal.s);

        let [quadHasFeature, pIsF2] = (() => {
          switch (tr.ft) {
            case 'hslices':
              return [j % 2 == 0];
            case 'vslices':
              return [i % 2 == 0];
            case 'spiral':
              return [(i + j) % T(tr.div / 2) == 0];
            case 'check':
              return [(i + j) % 2 == 0];
            case 'ring':
              return [j == T((N - 1) / 2)];
            default:
              return [tr.rng() <= ({
                'all': 1,
                'few': 0.1,
                'some': 0.3,
                'loads': 0.4,
              }[tr.ft] || -1), 0.5];
          }
        })();

        quads.push(qu);
        if (quadHasFeature) {
          let [fType, fClr] = (tr.rng() >= (pIsF2 || 0)) ? [tr.f1, tr.pal.f1] : [tr.f2, tr.pal.f2];
          if (fType != 'flat') {
            qu = Object.assign({}, qu);
            qu.clr = fClr;
            createFeature(quads, qu, fType, t);
          } else {
            qu.clr = fClr;
          }
        }
      }
    }

    // 3. Sort quads by distance and draw back to front for correct visibility.
    quads.sort((v1, v2) => v2.D - v1.D);
    for (let i in quads) {
      drawQuad(quads[i]);
    }
  },
  rng = mulberry32(parseInt(hash.substring(0, 10))),
  tr = traits(rng);

  return {
    orb: (ctx, size, t) => {
      tr.rng = rng.clone();
      render(ctx, size, t, tr);
    },
    tr
  }
};
