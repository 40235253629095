function colorName(clr) {
  if (clr.s < 30) {
    return clr.l > 50 ? 'snow' : 'charcoal';
  }

  // names spread uniformly; the base hue for each is 30 degrees times its index in the array.
  var names = ['crimson', 'sunset', 'lemon', 'olive', 'jungle', 'emerald', 'sky', 'azure', 'ocean', 'lavender', 'amethyst'];
  var name, minDist = Infinity;
  names.forEach((s, i) => {
    var dd = Math.min(Math.abs(clr.h - i * 30), Math.abs(clr.h - (i * 30 + 360)));
    if (dd < minDist) {
      [minDist, name] = [dd, s];
    }
  });
  if (clr.l < 50) {
    name = "inky " + name;
  } else if (clr.l >= 80) {
    name = "polished " + name;
  }
  return name;
}

function htraits(tr) {
  names = {};
  const name = (s) => names[s] || s;
  const cap = (s) => s.split(' ').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ');

  return {
    'Palette': cap(tr.pal_name),
    'Features Layout': cap(tr.ft),
    'Feature 1 Kind': (tr.f1 && cap(name(tr.f1)) || '-'),
    'Feature 2 Kind': (tr.f2 && cap(name(tr.f2)) || '-'),
    'Feature 1 Color': (tr.f1 && cap(colorName(tr.pal.f1)) || '-'),
    'Feature 2 Color': (tr.f2 && cap(colorName(tr.pal.f2)) || '-'),
    'Orb Color': cap(colorName(tr.pal.s)),
    'Complexity': tr.div,
    'Rarity': tr.rarity.toFixed(4) +"%",
    'Mods': cap(tr.lmod),
  };
}

module.exports = htraits;