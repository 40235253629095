const draw = require('./sketch');

module.exports = function traits(hash) {
  const impl = {
    floor: (x) => Math.floor(x),
    width: 400,
    height: 400,
    PI: Math.PI,
    TWO_PI: Math.PI * 2,
    HSL: 'hsl',
    RGB: 'rgb',
    HSB: 'hsb',
  };

  const p = new Proxy(impl, {
    get: (target, prop, receiver) => {
      if (prop in impl) {
        return impl[prop];
      }
      return () => {};
    }
  });
  return draw(p, hash);
}
