import Script from 'next/script';
import React, {useEffect, useRef, useState} from 'react';
import draw from './sketch.js';

type Props = {
  // A thing that looks like 0x13ba582f3f...
  hash?: string;
  density?: number;
  onTraits?: (traits: {[key: string]: string}) => void;
  saveRef?: React.MutableRefObject<((name: string) => void) | null>;
};

export default function Preview({hash, density, onTraits, saveRef}: Props) {
  const frame = useRef<HTMLDivElement | null>(null);
  const [loaded, setLoaded] = useState(() => typeof window !== 'undefined' && !!(window as any).p5);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (!loaded) {
      const interval = setInterval(() => {
        if ('p5' in window) {
          setLoaded(true);
        }
      }, 500);
      return () => clearInterval(interval);
    }

    const canvas = new (window as any).p5((p: any) => {
      p.setup = () => {
        const c = p.createCanvas(600, 400);
        if (density) {
          p.pixelDensity(density);
        }
        p.noLoop();

        if (saveRef) {
          saveRef.current = (name) => {
            p.saveCanvas(c, name, 'png');
          };
        }
      };
      p.draw = () => {
        const traits = draw(p, hash);
        onTraits && onTraits(traits);
      };
    }, frame.current);

    return () => {
      canvas.remove();
    };
  }, [loaded, hash, density, onTraits, saveRef]);

  return (
    <div ref={frame} className="frame">
      <Script id="p5-script" src="https://cdnjs.cloudflare.com/ajax/libs/p5.js/1.2.0/p5.js" strategy="lazyOnload" />
      <style jsx>{`
        .frame > :global(canvas) {
          width: 100% !important;
          height: auto !important;
        }
        .frame {
          aspect-ratio: 1.5;
        }
      `}</style>
    </div>
  );
}
