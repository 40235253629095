import {Collection} from '../collections';
import Preview from './Preview';

const collection: Collection = {
  dropId: 0,
  name: 'Geomes',
  item: 'Geome',
  description: [
    `This generative series explores a harmony created by combining simple geometric shapes and bold colors. Inspired by midcentury patterns - simples rules guide both color and shapes throughout to create unique expressions.`,
    `Each mint is randomly generated with over 75,000,000 possibilities, across 8 different properties.`,
  ].join('\n'),
  baseURL: process.env.BASE_URL || 'https://codemakes.art',
  image: '/images/geomes.png',
  aspectRatio: 1.5,
  twitter: '@codemakesart',
  price: '0.04',
  max: 2000,
  maxPerMint: 20,
  license: 'NFT',
  opensea: 'https://opensea.io/collection/the-geomes',
  Preview,
  traits: require('./traits.js'),
};

export default collection;
