import React from 'react';
import geomes from './geomes/collection';
import quasars from './quasars/collection';

type Props = {
  // A thing that looks like 0x13ba582f3f...
  hash?: string;
  density?: number;
  animated?: boolean;
  onTraits?: (traits: {[key: string]: string}) => void;
  saveRef?: React.MutableRefObject<((name: string) => void) | null>;
};

export type Collection = {
  dropId: number;
  name: string;
  item: string;
  description: string;
  baseURL: string;
  image: string;
  aspectRatio: number;
  twitter: string;
  price: string;
  max: number;
  maxPerMint: number;
  license: string;
  opensea: string;
  Preview: React.FunctionComponent<Props>;
  traits: (hash: string) => {[name: string]: string};
  rawTraits?: (hash: string) => {[name: string]: string};
};

const collections = [geomes, quasars];

export default collections;
