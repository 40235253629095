import {Collection} from '../collections';
import Preview from './Preview';

const collection: Collection = {
  dropId: 1,
  name: 'Quasars',
  item: 'Quasar',
  description: [
    `Some smoulder with subdued energy, some burn bright, and others are multi-faceted planetary hedgehogs. Quasars is an art collection of procedurally-generated objects pulsating for your delight.`,
    `Quasars are crafted by a talented computer wizard. Each token is generated from a random seed and uses simple JavaScript code to produce a stunning animated 3D object.`,
    `Each Quasar has 8 different traits picked at random. Some are more rare than others. Will you get the lucky one?`,
  ].join('\n'),
  baseURL: process.env.BASE_URL || 'https://codemakes.art',
  image: '/images/quasars.png',
  aspectRatio: 1,
  twitter: '@codemakesart',
  price: '0.01',
  max: 10000,
  maxPerMint: 20,
  license: 'NFT',
  opensea: 'https://opensea.io/collection/quasars-reborn/',
  Preview,
  traits: (hash) => {
    const {tr} = require('./render.js')(hash);
    const {Rarity, Palette, ...rest} = require('./htraits.js')(tr);
    return rest;
  },
  rawTraits: (hash) => {
    const {tr} = require('./render.js')(hash);
    const {rng, rarity, pal, ...raw} = tr;
    return raw;
  },
};

export default collection;
